.Cases {
    min-height: 100vh;
    padding-top: 80px;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.Cases .Wrapper {
    max-width: 1000px;
    height: 500px;
    margin: 0 auto;
    display: flex;
    background-color: aliceblue;
    align-items: center;
    justify-content: space-between;
}

.Cases .Wrapper div {
    flex-basis: 50%;
    padding: 3em;
}

.Cases h1 {
    max-width: 1000px;
    margin: 0 auto;
}