@import '../../styles/variables.css';

.FrameLeftNavbar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 60px;
 }

.FrameLeftNavbar ul {
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
}

.FrameLeftNavbar ul li {
    list-style: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.FrameLeftNavbar a {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    transform: rotate(-90deg);
    /* text-wrap: nowrap; */
}

.FrameRight {
    position: absolute;
    height: 100vh;
    width: 60px;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 60px;
}

.FrameRight p {
    transform: rotate(90deg);
    text-wrap: nowrap;
    color: var(--third-color);
    width: 20px;
}

.FrameBottom {
    position: absolute;
    width: 100vw;
    height: 60px;
    bottom: 0;
    z-index: -1;
}

.FrameTop {
    position: absolute;
    top: 0;
    width: 100vw;
    display: flex;
    height: 60px;
}

.FrameTop .logo {
    margin: 0 auto;
}

.FrameTop .logo img {
    height: 60px;
}