@import '../../styles/variables.css';

.AboveTheFold {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--main-color);
}

.AboveTheFold div {
    max-width: 80%;
}

.AboveTheFold h1 {
    font-size: 3em;
    color: #c9fb8f;
}

.AboveTheFold h2 {
    color: #fff;
}