*, *:before, *:after {
    box-sizing: inherit;
}

html, body {
    scroll-behavior: smooth;
}

.container {
    width: 100vw;
}