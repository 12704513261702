.Packages {
    height: 100vh;
    padding-top: 80px;
    color: #fff;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.Packages .Wrapper {
    display: flex;
    justify-content: center;
    gap: 1em;
    max-width: 1000px;
    margin: 0 auto;
}

.Packages div {
    flex-grow: 1;
    flex-basis: 20%;
}

.Packages div p {
    line-height: 1.25;
}

/*  --- mobile settings --- */
@media (max-width: 767px) {
    .Packages {
        height: unset;
        padding: 2em;
    }

    .Packages .Wrapper {
        flex-direction: column;
    }
}