.About {
    min-height: 100vh;
    padding-top: 80px;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.About .Wrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 3em;
}

.About .Wrapper p {
    color: #fff;
}

.About .innerWrap {
    display: flex;
    justify-content: space-between;
    gap: 3em;
}

.About .innerWrap div {
    flex-basis: 40%;
    order: 2;
}

.About .innerWrap #imgOfMindful {
    width: 450px;
    order: 2;
}

.About .Wrapper #imgOfUs {
    width: 450px;
}

/*  --- mobile settings --- */
@media (max-width: 767px) {
    .About {
        height: unset;
        padding: 2em;
    }

    .About .innerWrap {
        flex-direction: column;
    }
}