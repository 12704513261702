.CommingSoon {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #349891ff;
}

.CommingSoon .Wrapper {
    max-width: 80%;
}

.CommingSoon .Wrapper h1 {
    font-size: 5em;
    color: #c9fb8f;
}

.CommingSoon .Wrapper h2 {
    color: #fff;
}