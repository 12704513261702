.Footer {
    height: 180px;
    max-width: 100%;
    background-color: #333;
    color: #fff;
    padding: 0 2em 2em 0;
}

.Footer div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
}

.Footer p {
    margin: 0;
}