.CaseReport {
    min-height: 100vh;
    padding-top: 80px;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.CaseReport .Wrapper {
    max-width: 1000px;
    max-height: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.Collapse {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2em;
    transition: flex-basis 1.2s linear;
    overflow: hidden;
}

.Collapse.hidden {
    flex-basis: 10%;
}

.Collapse.hidden h2,
.Collapse.hidden p {
    display: none;
}

.Collapse.open {
    flex-basis: 80%;
    align-items: baseline;
}

.Collapse.open h2,
.Collapse.open p {
    display: flex;
}

#one,
#two,
#three {
    border-right: 1px solid black;
}