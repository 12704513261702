.CaseGallery {
    min-height: 100vh;
    padding-top: 80px;
    transition: background-color 0.5s ease, color 0.5s ease;
}

.CaseGallery .Wrapper {
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.CaseGallery .Wrapper div {
    padding: 2em;
}