@import '../../styles/variables.css';

.BookingDiv {
    min-height: 100vh;
    padding-top: 80px;
}

.BookingDiv .Wrapper {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
    gap: 2em;
}

.BookingDiv .bookingInfo {
    flex-basis: 60%;
}

.BookingDiv form {
    flex-basis: 40%;
}

.Collapse {
    transition: max-height 1s ease; /* Övergångseffekt */
    overflow: hidden; /* Döljer överskottet */
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.Collapse textarea {
    height: 4em;
}

.Collapse .next {
    margin-left: auto;
    width: 40%;
    background-color: var(--fourth-color);
    color: #fff;
    border: none;
}

.Collapse.hidden {
    max-height: 50px; /* Döljer diven */
}

.Collapse.open {
    max-height: 200px; /* Visar diven */
}

/*  --- mobile settings --- */
@media (max-width: 767px) {
    .BookingDiv {
        padding: 2em;
    }

    .BookingDiv .Wrapper {
        flex-direction: column;
    }
}